import React, { useState } from 'react';
import CustomBackdropLoadingInsideDiv from '../../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';
import { ESuggestionStatus, ISuggestion } from '../../../definitions/entities.definition';
import { groupSuggestions } from '../../../selectors/recruitingSuggestion.selectors';
import RenderSuggestionListGroup from './RenderSuggestionListGroup';
import CustomTableFooter from '../../../components/CustomTable/CustomTableFooter';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { EEntityView } from '../../../definitions/ui.definitions';
import AddEditSuggestionGroupView from '../../RecruitingPage/AddEditSuggestionGroupView';
import { useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';

interface IProps {
    total: number;
    suggestions: ISuggestion[];
    loading: boolean;
    vacancyId: number;
    parentView: EEntityView;
    onReload: () => void;
    onDragEnd?: (result: DropResult) => void;
    groupBy?: 'status'|'progressStatus'|'group';
}

const SuggestionList: React.FC<IProps> = (props) => {
    const groupBy = props.groupBy || 'status';
    const total = props.total;
    const vacancyId = props.vacancyId;
    const suggestionGroups = groupSuggestions(groupBy, props.suggestions);
    const loading = props.loading;
    const parentView = props.parentView;
    const suggestionGroupsArray = Object.keys(suggestionGroups);
    const [editGroupId, setEditGroupId] = useState(0);
    const vacancy = useSelector((store: IStore) => store.entities.vacancies.byId[vacancyId]);

    return <>
        <AddEditSuggestionGroupView
            groupId={editGroupId}
            vacancyId={props.vacancyId}
            onClose={() => setEditGroupId(0)}
        />
        <div className={"flexColumn flex1"}>
            <div className={"overflow flex1 marginTop10 flexContainerColumn gap5 paddingRight5"}>
                <CustomBackdropLoadingInsideDiv open={loading} />
                {total === 0 &&
                    <>Keine Daten vorhanden</>
                }
                <DragDropContext onDragEnd={groupBy === 'group' && props.onDragEnd ? props.onDragEnd : () => console.log(1)}>
                    {groupBy === 'group'
                        ? <>
                            {vacancy.groups.sort((a, b) => a.ranking < b.ranking ? -1 : 1).map((group) => {
                                const suggestions = suggestionGroups[group.ranking + '. ' + group.title] ? suggestionGroups[group.ranking + '. ' + group.title].sort((a, b) => a.ranking < b.ranking ? -1 : 1) : [];

                                return <>
                                    <RenderSuggestionListGroup
                                        key={group.id}
                                        groupIdentifier={group.ranking + '. ' + group.title}
                                        suggestions={suggestions}
                                        parentView={parentView}
                                        groupBy={groupBy}
                                        onEdit={() => setEditGroupId(group.id)}
                                        groupId={group.id}
                                    />
                                </>
                            })}
                        </>
                        : <>
                            {suggestionGroupsArray.map((status) => {
                                const groupIdentifier = status as ESuggestionStatus;
                                const suggestions = suggestionGroups[status].sort((a, b) => a.ranking < b.ranking ? -1 : 1);

                                return <>
                                    <RenderSuggestionListGroup
                                        key={status}
                                        groupIdentifier={groupIdentifier}
                                        suggestions={suggestions}
                                        parentView={parentView}
                                        groupBy={groupBy}
                                    />
                                </>
                            })}
                        </>}

                </DragDropContext>

            </div>
            <CustomTableFooter
                style={{height: 35}}
                text={'Favoriten: ' + total}
                onReload={props.onReload}
            />
        </div>
    </>;
}

export default SuggestionList;
