import { Omit } from '@material-ui/core';
import { TRole } from './actor.definitions';
import { ISkillMatch, ISkillMatchResult } from './matching.definitions';
import { EDocumentStatus, EInvoiceType, IInvoiceItemForm } from './billingPage.definitions';
import {
    IGroupedSuggestionWithSourceEvaluationData,
    IGroupedSuggestionWithStatusEvaluationData,
    IGroupedTimePerEmployeeData,
} from './dashboardPage.definitions';
import { EBillingCalculationBy, EBillingType } from './billingConfiguration.definitions';
import { ICustomerInquiry, ICustomerRecruitingListModel } from './entities.customer.definitions';

export interface IBillingVariantTemplate {
    id: number;
    title: string;
    calculationBy: EBillingCalculationBy;
    calculationFactorInvoice1: number;
    secondInvoice: boolean;
    calculationFactorInvoice2: number;
}

export interface IBillingSetting {
    billingType?: EBillingType;
    billingVariantTemplate?: IBillingVariantTemplate;
    automaticGeneration: boolean;
    invoiceCreationAfter?: EVacancyInvoiceCreationAfter;
    customBillingCalculationBy?: EBillingCalculationBy;
    customBillingCalculationFactorInvoice1?: number;
    customBillingSecondInvoice: boolean;
    customBillingCalculationFactorInvoice2?: number;
}

export interface ICompany extends IBillingSetting {
    id: number;
    name: string;
    short: string;
    taxNumber: string;
    salesTaxIdentificationNumber: string;
    comment: string;
    employeeSizeCategory: null|number;
    homepageUrl: string;
    mailAddress: string;
    phoneNumber: string;
    branches: IBranch[];
    created: string;
    updated: string;
    lastActivity: string;
    totalContracts: number;
    successRate: number;
    responsibleEmployee: IEmployeePartial;
    crawler: boolean;
    parentCompany?: ICompanyPartial;
    termsOfPayment?: ITermsOfPayment;
    rating: number;
    isCustomer: boolean;
    shareWithEmployees: IEmployeePartial[];
}

export interface ITermsOfPayment {
    id: number;
    days: number;
    title: string;
}

export interface ICompanyForApi extends Partial<Omit<ICompany,
    'branches' |
    'created' |
    'updated' |
    'lastActivity' |
    'totalContracts' |
    'successRate' |
    'responsibleEmployee' |
    'parentCompany' |
    'termsOfPayment'|
    'shareWithEmployees'
>> {
    branchIds?: number[];
    parentCompanyId?: number;
    responsibleEmployeeId?: number;
    termsOfPaymentId?: number;
    shareWithEmployeeIds?: number[];
}

export interface ICompanyLocation {
    id: number;
    differentCompanyName?: string;
    company: ICompany;
    address: string;
    zipCode: string;
    city: string;
    comment: string;
    created: string;
    updated: string;
}

export interface ICompanyLocationPartial {
    id: number;
    address?: string;
    zipCode: string;
    city: string;
}

export interface ICompanyLocationForApi extends Partial<Omit<ICompanyLocation, 'company' | 'created' | 'updated'>> {
    companyId: number;
}

export interface ICompanyContact {
    id: number;
    fullName: string;
    name: string;
    firstName: string;
    salutation: TSalutation;
    title: string;
    position: string;
    comment: string;
    mailAddress: string;
    mailAddress2: string;
    linkedInUrl: string;
    xingUrl: string;
    phoneNumber: string;
    company: ICompanyPartial;
    created: string;
    updated: string;
    birthDate: string;
}

export interface ICompanyContactPartial {
    id: number;
    name: string;
    firstName: string;
    companyId: number;
    fullName: string;
    phoneNumber: string;
    mailAddress: string;
    company: ICompanyPartial;
}

export interface ICandidateDocument {
    id: number;
    candidate: ICandidate;
    displayFileName: string;
    type: TCandidateDocumentType;
    created: string;
    updated: string;
    fileType: string;
}

export interface ICompanyDocument {
    id: number;
    company: ICompany;
    displayFileName: string;
    type: TCompanyDocumentType;
    created: string;
    updated: string;
}

export interface ICandidateDocumentForApi extends Partial<Omit<ICandidateDocument, 'candidate' | 'created' | 'updated'>> {
    id?: number;
    candidateId?: number;
    fileContent?: string | ArrayBuffer | null;
}

export interface ICompanyDocumentForApi extends Partial<Omit<ICompanyDocument, 'company' | 'created' | 'updated'>> {
    id?: number;
    companyId?: number;
    fileContent?: string | ArrayBuffer | null;
}

export interface IRecruitingDocument {
    id: number;
    recruiting: IRecruiting;
    displayFileName: string;
    type: TRecruitingDocumentType;
    created: string;
    updated: string;
}

export interface IRecruitingDocumentForApi extends Partial<Omit<IRecruitingDocument, 'recruiting' | 'created' | 'updated'>> {
    recruitingId: number;
    fileContent?: string | ArrayBuffer | null;
}

export enum ETodo {
    manual,
    trialPeriodFailed,
    interviewFeedbackMissing = 4,
    invoiceMissing,
    contractStartFeedbackMissing,
    trialPeriodFeedbackMissing,
    recruitingInformationMissing,
    suggestionWithPhoneNumber= 10,
    suggestionAppointmentFeedback= 13,
    congratulationMail,
    paymentMissing,
    suggestionResponsibleChanged
}

export interface ITodo {
    id: number;
    todoEnum: ETodo;
    description: string;
    candidate?: ICandidatePartial;
    recruitingSuggestion?: ISuggestionPartial;
    companyContact?: ICompanyContactPartial;
    employee: IEmployeePartial;
    vacancy?: IVacancyPartial;
    company?: ICompanyPartial;
    createdByUser: IUserPartial;
    recruiting?: IRecruitingPartial;
    invoiceId?: number;
    activityId?: number;
    pin: boolean;
    ranking?: number;
    dueDate?: string;
    dueDateTo?: string;
    flexibleTime: boolean;
    new: boolean;
    completed: boolean;
    created: string;
    daysLeft?: number;
    shareWithEmployees: IEmployeePartial[];
}

export interface ITodoWithDue extends Omit<ITodo, 'dueDate' | 'dueDateTo'> {
    dueDate: string;
    dueDateTo: string;
}

export interface ITodoPartial {
    id: number;
    todoEnum: ETodo;
}

export interface ITodoForApi extends Partial<Omit<ITodo,
    'employee' |
    'author' |
    'candidate' |
    'companyContact' |
    'vacancy' |
    'company' |
    'suggestions' |
    'activityId' |
    'todoEnum' |
    'created' |
    'updated' |
    'shareWithEmployees'
    >> {
    employeeId?: number;
    companyId?: number;
    candidateId?: number;
    vacancyId?: number;
    companyContactId?: number;
    authorId?: number;
    new?: boolean;
    pin?: boolean;
    completed?: boolean;
    skip?: boolean;
    shareWithEmployeeIds: number[];
}

export enum ENewsType {
    NEWS_TYPE_CANDIDATE_BIRTHDAY,
    NEWS_TYPE_CONTACT_PERSON_BIRTHDAY,
    NEWS_TYPE_CANDIDATE_STARTS_TODAY,
    NEWS_TYPE_CANDIDATE_STARTS_IN_ONE_WEEK,
    NEWS_TYPE_CANDIDATE_TRIAL_PERIOD_END,
    NEWS_TYPE_VACANCY_OFFLINE
}

export interface INewsGroup {
    [group: string]: INews[]
}

export interface IMailGroup {
    [group: string]: IMailboxMail[]
}

export interface IRecruitingGroup {
    [group: string]: IRecruitingListModel[]
}

export interface IProcessGroup {
    [group: string]: IProcessAggregation[]
}

export interface IApplicationGroup {
    [group: string]: IApplication[]
}

export interface ISuggestionsGrouped {
    [group: string]: ISuggestion[]
}

export interface INews {
    id: number;
    dateTime: string;
    newsType: ENewsType;
    candidate?: ICandidatePartial;
    vacancy?: IVacancyPartial;
    webVacancy?: IWebVacancyPartial
    companyContact?: ICompanyContactPartial;
    recruiting?: IRecruitingPartial;
    highlight: boolean;
}


export interface IActivityData {
    [data: string]: any;
}

export interface IInvoiceItem {
    id: number;
    invoice: IInvoice;
    ranking: number;
    title: string;
    comment?: string;
    amount: number;
    price: number;
    priceTotal: number;
}

export interface IBillableData {
    id: number;
    shouldDate: string;
    recruiting?: IRecruitingPartial;
    vacancy?: IVacancyPartial;
    invoiceType: EInvoiceType;
    totalNet: number;
}

export interface IInvoice {
    id: number;
    date: string;
    dueDate: string;
    number: string;
    status: EDocumentStatus;
    invoiceType: EInvoiceType;
    company?: ICompanyPartial;
    companyLocation?: ICompanyLocationPartial;
    companyContact?: ICompanyContactPartial;
    recruiting?: IRecruitingPartial;
    vacancy?: IVacancyPartial;
    companyName: string;
    address: string;
    addressLine2?: string;
    zipCode: string;
    city: string;
    totalNet: number;
    discountInPercent?: number;
    discountSum?: number;
    totalNetAfterDiscount: number;
    vat: number;
    vatSum: number;
    totalGross: number;
    comment?: string;
    openToPay: number;
    items: IInvoiceItem[];
    termsOfPayment: ITermsOfPayment;
    isRemindAble: boolean;
    isAbleForDunning: boolean;
    remindOfPaymentCreated: boolean;
    remindOfPaymentDate: null|string;
    remindOfPaymentDueDate: null|string;
    dunningLetters: IDunningLetter[];
}

export interface IDunningLetter {
    id: number;
    date: string;
    dueDate: string;
    invoice: IInvoice;
    tax: number;
    fee: number;
    openSum: number;
    dunningSum: number;
    level: number;
}

export enum ETermsOfPayment {
    Days14 = 1,
    Days21= 2
}

export interface IInvoiceForApi extends Partial<Omit<IInvoice,
    'company' |
    'companyLocation' |
    'companyContact' |
    'recruiting' |
    'totalNetAfterDiscount' |
    'totalNet' |
    'vat' |
    'vatSum' |
    'totalGross' |
    'openToPay' |
    'items' |
    'termsOfPayment'
    >> {
    termsOfPaymentId?: number;
    companyId?: number;
    companyLocationId?: number;
    companyContactId?: number|null;
    items: IInvoiceItemForm[]
}

export interface ISuccessActivity {
    id: number;
    dateTime: string;
    createdByUser: IUserPartial;
    employee?: IEmployeePartial;
    recruiting?: IRecruitingPartial;
    vacancy?: IVacancyPartial;
    recruitingSuggestion?: ISuggestionPartial;
    text: string;
}

export type TEntity = 'ENTITY_CANDIDATE'|'ENTITY_SUGGESTION'|'ENTITY_EMPLOYEE'|'ENTITY_COMPANY_CONTACT'|'ENTITY_COMPANY'|'ENTITY_RECRUITING'|'ENTITY_VACANCY';

export interface IMailParticipant {
    mailbox: string;
    host: string;
    mailAddress: string;
    personal?: string;
    entityId?: number;
    entityType?: TEntity;
    isInternalMailAddress: boolean;
}

export type TMailProperty = 'from'|'to'|'cc'|'bcc'|'subject'|'content'|'participants';

export interface IMailRelation {
     id: number;
     mailProperty: TMailProperty;
     referenceValue: string;
     autoAssigned: boolean;
     candidate?: ICandidatePartial;
     recruiting?: IRecruitingPartial;
     suggestion?: ISuggestionPartial;
     vacancy?: IVacancyPartial;
     company?: ICompanyPartial;
     companyContact?: ICompanyContactPartial;
}

export interface IMail {
    id: number;
    dateTime: string;
    subject: string;
    message: string;
    from: IMailParticipant;
    to: IMailParticipant[];
    cc: IMailParticipant[];
    relations: IMailRelation[];
    hasAttachments: boolean;
    flagForDebug: boolean;
}

export interface IMailboxMail extends IMail{
    mailboxMailId: number;
    read: boolean;
}

export interface IMailPartial {
    id: number;
    from: string;
    to: string;
    subject: string;
    message: string;
    dateTime: string;
    sent: boolean;
}

export interface IChat {
    participants: IPerson[];
    nodes: IChatNode[];
    total: number;
}

export interface IChatNode {
    id: number;
    date: string;
    direction: 'send'|'received';
    module: EActivityModule;
    type: TActivityType;
    action: EActivityAction;
    shortText: string;
    icon?: string;
    longText?: string;
    sender: IPerson|IVacancyPartial|ICompanyPartial|IApplicationPartial;
    senderType: 'candidate'|'suggestion'|'employee'|'company_contact'|'company'|'vacancy'|'application'|'recruiting';
    receiver: IPerson|IVacancyPartial|ICompanyPartial|IApplicationPartial|ISuggestionPartial;
    receiverType: 'candidate'|'suggestion'|'employee'|'company_contact'|'company'|'vacancy'|'application'|'recruiting';
}

export interface IPerson {
    name: string;
    firstName: string;
    fullName: string;
}

export interface IActivity {
    module: EActivityModule;
    action: TActivityAction;
    mail: IMailPartial;
    suggestionAction: ESuggestionAction;
    data: IActivityData;
    id: number;
    userInput?: string;
    dateTime: string;
    type: TActivityType;
    recruiting?: IRecruitingPartial;
    recruitingSuggestion?: ISuggestionPartial;
    company?: ICompanyPartial;
    candidate?: ICandidatePartial;
    companyContact?: ICompanyContactPartial;
    application?: IApplication;
    vacancy?: IVacancyPartial;
    webVacancy?: IWebVacancyPartial;
    todo?: ITodoPartial;
    employee?: IEmployeePartial;
    createdByUser: IUser;
    feedbackType?: EFeedbackType,
    interviewType?: EInterviewType,
    interviewCancelled?: boolean,
    feedbackCompany?: string,
    feedbackCandidate?: string,
    feedbackCompanyDate?: string,
    feedbackCandidateDate?: string,
    created: string;
    updated: string;
    estimatedTime: number;
    recruitingAbort: boolean;
    contractOffer: boolean;
}

export interface IActivityForApi extends Partial<Omit<IActivity,
    'candidate' |
    'employee' |
    'vacancy' |
    'application' |
    'company' |
    'companyContact' |
    'recruiting' |
    'recruitingSuggestion' |
    'created' |
    'updated'
>> {
    employeeId?: number;
    companyId?: number;
    companyContactId?: number;
    vacancyId?: number;
    candidateId?: number;
    applicationId?: number;
    recruitingId?: number;
    recruitingSuggestionId?: number;
    todoId?: number;
    finishedTrialPeriod?: boolean;
    interviewType?: EInterviewType,
    dateOfAbort?: string;
    abortedBy?: ERecruitingAbortedBy;
    abortComment?: string;
    declineReason?: ERecruitingDeclineReason;
    candidateFollowupDate?: string;
    recruitingAbort?: boolean;
}

export interface IContractOfferForApi {
    id?: number;
    userInput?: string;
    dateTime: string;
    employeeId?: number;
    companyContactId?: number;
    recruitingId?: number;
    offeredContractBeginningDate?: string;
    offeredContractSalary?: number;
}

export interface IAddInterviewForApi {
    userInput?: string;
    dateTime: string;
    employeeId?: number;
    companyContactId?: number;
    recruitingId?: number;
    interviewType?: EInterviewType;
}
export interface IUpdateInterviewForApi {
    id: number;
    userInput?: string;
    dateTime: string;
    employeeId?: number;
    companyContactId?: number;
    recruitingId?: number;
    interviewType?: EInterviewType;
    interviewCancelled?: boolean;
    feedbackCompany?: string;
    feedbackCandidate?: string;
    feedbackCompanyDate?: string;
    feedbackCandidateDate?: string;
    dateOfAbort?: string;
    abortedBy?: ERecruitingAbortedBy;
    abortComment?: string;
    declineReason?: ERecruitingDeclineReason;
    candidateFollowupDate?: string;
    recruitingAbort?: boolean;
    contractOffer?: boolean;
    offeredContractBeginningDate?: string;
    offeredContractSalary?: number;
}

export type TCandidateType = 'candidate'|'acquisition';

export interface ICandidateListModel {
    id: number;
    type: TCandidateType;
    name: string;
    created: string;
    age?: number;
    daysAwayFromBirthday?: number;
    firstName: string;
    fullName: string;
    externalReference?: string;
    address: string;
    zipCode: string;
    city: string;
    country?: ICountry;
    salutation: TSalutation;
    topCandidate: boolean;
    unattractive: boolean;
    topInCommunication: boolean;
    birthDate: null|string;
    skills: number;
    lastActivity: string;
    latestDocumentCvId?: number;
    sendBirthdayWishes: boolean;
    desiredJobDescription: string;
    currentJobDescription: string;
    source: ECandidateSource;
    responsible: IEmployeePartial;
    distanceText: string;
    durationText: string;
    matchedOptionalSkillIds?: number[];
    hasOpenSuggestion: boolean;
    hasOpenRecruiting: boolean;
    hasContractRecruiting: boolean;
    hasContactWithRecruiter: boolean;
    totalContracts: number;
    salaryUnit: 'year'|'month',
    expectedMonthSalary?: number;
    expectedMonthSalaryTo?: number;
    expectedYearSalary?: number;
    expectedYearSalaryTo?: number;
    expectedSalaryString?: string;
    matchedSkills: ISkillMatchResult;
    followupDate: string;
    birthdayMailSend: boolean;
    candidateProfiles: ICandidateProfiles;
    topInPotential: boolean;
    numberOfEmployers?: number;
    level?: ECandidateLevel,
    educationLevel?: EEducationLevel;
}

export enum EApplicationStatus {
    open = 'open',
    inspection = 'inspection',
    declined = 'declined',
    recruiting = 'recruiting'
}

export enum ELanguageLevel {
    unknown,
    basic,
    fluent,
    good,
    mother_language
}

export interface ICountry {
    id: number;
    code: string;
    name: string;
}

export interface ILanguage {
    id: number;
    code: string;
    name: string;
}

export interface ICandidateLanguage {
    id: number;
    candidate?: ICandidatePartial;
    language: ILanguage;
    level: ELanguageLevel;
    addedByParser?:boolean;
}

export interface IAssignLanguage {
    language: ILanguage,
    level: ELanguageLevel
}

export type TCandidateUrlType = 'xing'|'linkedIn'|'freelancerMap'|'github'|'homepage'|'further';

export interface ICandidateUrl {
    id: number;
    type: TCandidateUrlType;
    url: string;
    comment?: string;
}

export interface ICandidateAddress {
    id: number;
    default: boolean;
    address: string;
    city: string;
    zipCode: string;
    country?: ICountry;
    countryId?: number;
    comment?: string;
}

export interface ICandidatePhoneNumber {
    id: number;
    phoneNumber: string;
    comment?: string;
    default: boolean;
}

export interface ICandidateMailAddress {
    id: number;
    mailAddress: string;
    default: boolean;
    comment?: string;
}

export interface ICandidateProfiles {
    photoId?: string;
    urls: ICandidateUrl[];
    addresses: ICandidateAddress[];
    mailAddresses: ICandidateMailAddress[];
    phoneNumbers: ICandidatePhoneNumber[];
    cvId?: number;
    internalProfileId?: number;
    arbeitsamtUrl?: string;
}

export interface ICandidate {
    id: number;
    type: 'candidate'|'acquisition';
    name: string;
    firstName: string;
    fullName: string;
    salutation: TSalutation;
    title: string;
    comment: string;
    birthDate: null|string;
    age?: number;
    skills: ICandidateSkill[];
    languages: ICandidateLanguage[];
    lastActivity: string;
    created: string;
    responsible: IEmployeePartial;
    topCandidate: boolean;
    topInCommunication: boolean;
    desiredJobDescription: string;
    currentJobDescription: string;
    salaryUnit: 'year'|'month',
    expectedSalary: number;
    expectedSalaryTo: number;
    homeOfficeDesire: EHomeOfficeDesire|null;
    moveWillingness: string;
    noticePeriod: string;
    leader: boolean;
    yearsOfWorkExperience: string;
    typus: string;
    proudOf: string;
    thoughtsAboutTeamAndAppreciation: string;
    flexibility: string;
    hasDriverLicense: boolean;
    wishDateOfStart: string;
    partTimeFullTime: null|TPartTimeFullTime;
    freelancer: boolean;
    newsletterEnabled: boolean;
    source: ECandidateSource;
    initialSuggestion?: ISuggestionPartial;
    followupDate?: string;
    imagePath?: string;
    followupComment: string;
    generateMatchingCache: boolean;
    updated: string;
    totalContracts: number;
    sendBirthdayWishes: boolean;
    jobTemplate?: IJobTemplate;
    professions: ICandidateProfession[];
    latestDocumentCvId?: number;
    countDocuments: number;
    topInPotential: boolean;
    numberOfEmployers?: null|number;
    level?: null|ECandidateLevel;
    educationLevel?: null|EEducationLevel;
    unattractive: boolean;
    externalReference?: string;
    urls: ICandidateUrl[];
    addresses: ICandidateAddress[];
    mailAddresses: ICandidateMailAddress[];
    phoneNumbers: ICandidatePhoneNumber[];
    imageUrl?: string;
    candidateProfiles: ICandidateProfiles;
    address: string;
    zipCode: string;
    city: string;
    country?: ICountry;
}

export interface ICandidateProfession {
    id: number;
    titleFreeText: string|null;
    cvParserContent: string|null;
    fromYear: number;
    fromMonth: number;
    toYear: null|number;
    toMonth: null|number;
    type: EProfessionType|null;
    company: string|null;
    comment: string|null;
    untilToday: boolean;
}

export interface ICandidateProfessionForApi extends Partial<ICandidateProfession> {
    id?: number;
    candidateId: number;
}

export interface ICandidateAddressForApi extends Partial<Omit<ICandidateAddress, 'country'>> {
    countryId?: number;
}


export enum ECandidateLevel {
    junior = 'junior',
    professional = 'professional',
    senior = 'senior'
}

export enum EEducationLevel {
    education = 'education',
    study = 'study',
    careerChanger = 'career_changer'
}
export enum EProfessionType {
    EDUCATION = 'education',
    STUDY = 'study',
    JOB = 'job',
    INTERNSHIP = 'internship'
}

export interface IExternalCandidateInformation {
    firstName: string;
    name: string;
    city: string;
    currentJobDescription: string;
    birthDate?: string;
    skills: IExternalSkill[];
    xingUrl?: string;
    linkedInUrl?: string;
}

export interface ICandidateSkillAssign {
    id: number;
    skillId?: number;
    externalSkillId?: number;
    importantSkill: boolean;
}

export interface ICandidateLanguageAssign {
    id: number;
    languageId: number;
    level: ELanguageLevel;
}

export interface IVacancySkillAssign {
    id: number;
    skillId: number;
    importantSkill: boolean;
}

export interface ICandidateForApi extends Partial<Omit<ICandidate,
    'responsible' |
    'skills' |
    'addresses' |
    'languages' |
    'fullName' |
    'country' |
    'lastActivity'>> {
    applicationVacancyId?: number;
    addresses?: ICandidateAddressForApi[];
    mailAddresses?: ICandidateMailAddress[];
    urls?: ICandidateUrl[];
    phoneNumbers?: ICandidatePhoneNumber[];
    skills?: ICandidateSkillAssign[];
    languages?: ICandidateLanguageAssign[];
    appendSkills?: ICandidateSkillAssign[];
    responsibleId?: number;
    externalSkillToSkillMapping?: {[externalSkillId: number]: number;};
    importCvPath?: string;
    importCvName?: string;
    fieldsIgnoredForValidation: string[];
}

export enum EVacancyInvoiceCreationAfter {
    beginning = 'INVOICE_CREATION_AFTER_CONTRACT_BEGINNING',
    signing = 'INVOICE_CREATION_AFTER_CONTRACT_SIGNING',
}

export interface IVacancyPartial {
    id: number;
    title: string;
    url?: string;
    company: ICompanyPartial;
    companyLocation: ICompanyLocationPartial;
    responsibleContact?: ICompanyContactPartial;
    responsibleEmployee: IEmployeePartial;
    billingType?: EBillingType
    salaryFrom: number;
    salaryTo: number;
    webVacancyId?: number;
    count: number;
}

export interface IVacancyStatistic {
    countSuggestionTotal: number;
    countSuggestionActive: number;
    countSuggestionDeclined: number;
    countApplicationTotal: number;
    countApplicationDeclined: number;
    countApplicationActive: number;
    countApplicationAccepted: number;
    countApplicationAddedInTimeRange: number;
    countApplicationDeclinedInTimeRange: number;
    countApplicationAcceptedInTimeRange: number;
    countRecruitingActive: number;
    countRecruitingTotal: number;
    countRecruitingDeclinedAfterSuggestion: number;
    countRecruitingInterview: number;
    countRecruitingDeclinedAfterInterview: number;
    countRecruitingContract: number;
    countRecruitingAddedInTimeRange: number;
    countRecruitingDeclinedInTimeRange: number;
    countRecruitingInterviewedInTimeRange: number;
    countRecruitingContractedInTimeRange: number;
    companyFeedbackDurationAfterSuggestion: number;
    suggestionDeclinedReasons: IGroupedSuggestionWithSourceEvaluationData[];
    declineReasonSources: ECandidateSource[];
    timePerEmployee: IGroupedTimePerEmployeeData[];
    suggestionSources: IGroupedSuggestionWithStatusEvaluationData[];
    applicationSources: IGroupedSuggestionWithStatusEvaluationData[];
    suggestionTrend: number;
    companySuccessRate: number;
    timeEstimationTotal: number;
    timeEstimationTotalOfSuggestions: number;
    timeEstimationTotalOfRecruitings: number;
    timeEstimationAveragePerSuggestion: number;
    timeEstimationAveragePerRecruiting: number;
    countSuggestionAddedInTimeRange: number;
    countSuggestionDeclinedInTimeRange: number;
    countSuggestionAcceptedInTimeRange: number;
    countSuggestionContactCountInTimeRange: number;
    hasProcessesForDueDate: boolean;
}

export interface IVacancy extends IBillingSetting {
    id: number;
    ranking: number;
    count: number;
    possibleCommission: number;
    completed: boolean;
    pin: boolean;
    company: ICompanyPartial;
    companyLocation: ICompanyLocationPartial;
    title: string;
    url?: string;
    urlExtern?: string;
    description: string;
    comment: string;
    skills: IVacancySkill[];
    responsibleEmployee: IEmployeePartial;
    responsibleContact?: ICompanyContactPartial;
    created: string;
    updated: string;
    salaryFrom: number;
    salaryTo: number;
    homeOfficePossibility: null|EHomeOfficePossibility;
    needDriverLicense: boolean;
    withLeadership: boolean;
    webVacancy?: IWebVacancy;
    copyFromVacancy?: IVacancyPartial;
    customerFeedbackMissing: boolean;
    lastActivity: string;
    activeSince: string;
    filter?: string;
    languages: ILanguage[];
    partTime: boolean;
    trend: number;
    countApplications: number;
    countApplicationsWithStatusOpen: number;
    countRecruitings: number;
    countRecruitingsWithStatusContract: number;
    countRecruitingsWithStatusOpen: number;
    countSuggestions: number;
    countSuggestionsWithStatusOpen: number;
    countSuggestionsWithStatusDeclined: number;
    countSuggestionsWithStatusAccepted: number;
    suggestionDueDate?: null|string;
    suggestionDueSinceDate?: null|string;
    groups: ISuggestionGroup[];
}

export interface IPluginLanguage {
    language: string;
    level: string;
}

export interface IPluginSkill {
    source: 'linkedIn'|'xing',
    importantSkill: boolean;
    skill: string
}

export interface IPluginCandidate {
    firstName: string,
    birthDate: string,
    mailAddress: string,
    websiteUrl: string,
    lastName: string,
    currentJobDescription: string,
    city: string,
    imageUrl: string,
    languages: IPluginLanguage[],
    skills: IPluginSkill[],
}

export interface IVacancyOrWebVacancy {
    id: number;
    vacancy?: IVacancyPartial;
    webVacancy?: IWebVacancyPartial;
    company: ICompanyPartial;
    title: string;
    homeOfficePossibility?: EHomeOfficePossibility;
    responsibleEmployee: IEmployeePartial;
    created: string;
    distanceText: string;
    durationText: string;
    distanceLocation: string;
    matchedSkills: ISkillMatch[];
    isRecruiting: boolean;
    isSuggested: boolean;
}

export interface IVacancyListModel extends IVacancy {
    countOpenFavorites: number;
    countOpenProcesses: number;
    countProcesses: number;
    countAbortedProcesses: number;
    countContracts: number;
}

export interface IBulkUpdateVacancyInvoiceCreationVariant {
    companyId: number;
}

export interface IVacancyForApi
    extends Partial<
        Omit<
            IVacancy,
            | 'company'
            | 'skills'
            | 'companyLocation'
            | 'responsibleEmployee'
            | 'responsibleContact'
            | 'possibleCommission'
            | 'webVacancy'
            | 'created'
            | 'updated'
            | 'copyFromVacancy'
            | 'languages'
            >
        > {
    skills?: IVacancySkillAssign[];
    responsibleEmployeeId?: number;
    responsibleContactId?: number;
    companyId?: number;
    companyLocationId?: number;
    webVacancyId?: number;
    copyFromVacancyId?: number;
    languageIds: number[];
}

export interface IExternalSkill {
    id: number;
    name: string;
    source: ECandidateSource;
    skill?: ISkillPartial;
}

export interface IExternalSkillForApi {
    id: number;
    name?: string;
    skillId?: number;
}

export interface IMailbox {
    id: number;
    username: string;
    defaultFolderId: number;
    folderTree: IMailboxFolderTreeNode[];
}

export interface IMailboxFolderTreeNode {
    id: number;
    text: string;
    parentFolderId: number;
    children: IMailboxFolderTreeNode[];
    countUnread: number;
}

export interface ISkillTreeNode {
    id: number;
    text: string;
    nodePath: string;
    parentSkillId: number;
    children: ISkillTreeNode[];
    countUsed: number;
    childrenCountUsed: number;
    expanded: boolean;
    hasKeywords: boolean;
    keywords: string;
    generateMatchingCache: boolean;
}

export interface ICvParseResult {
    name: null|string;
    firstName: null|string;
    salutation: null|TSalutation;
    skills: ISkillPartial[];
    birthDate: null|string;
    city: null|string;
    zipCode: null|string;
    address: null|string;
    xingUrl: null|string;
    linkedInUrl: null|string;
    languages: ICvParseLanguage[];
    mailAddress: null|string;
    phoneNumber: null|string;
    mobilePhoneNumber: null|string;
    imagePath: null|string;
    file: null|string;
}

export interface ICvParseLanguage {
    language: ILanguage;
    level: ELanguageLevel;
}

export interface ISkill {
    id: number;
    name: string;
    path: string;
    usedByCandidates: number;
    usedByVacancies: number;
    keywords: string;
    parentSkill?: ISkill;
}

export interface IPostSkillForAPI {
    name?: string;
    parentSkillId?: number;
    keywords?: string;
}

export interface IPutSkillForAPI {
    id: number;
    name?: string;
    parentSkillId?: number;
    keywords?: string;
    replace?: boolean;
    replaceWithSkillId?: number;
    deleteOldSkill?: boolean;
    deleteAssignments?: boolean;
    addToTemplateId?: number;
}

export interface ISkillPartial {
    id: number;
    name: string;
    keywords: string;
}

export interface ISkillDetail {
    id: number;
    skill: ISkill;
    similarToJobTitle: {
        jobTitle: string;
        count: number;
        countInVacancies: number;
        countInCandidates: number;
        shareInVacancies: number;
        shareInCandidates: number;
        share: number;
    }
}

export interface IEntitySkill {
    id: number;
    skill?: ISkillPartial;
    externalSkill?: IExternalSkill;
    importantSkill: boolean;
    addedByParser: boolean;
    count: number;
}

export interface ISkillSelect {
    id: number;
    selected: boolean;
    skillDetail: ISkillDetail;
}

export interface ICandidateSkill extends IEntitySkill {
    identifier: 'candidate';
}

export interface IVacancySkill extends IEntitySkill {
    identifier: 'vacancy';
}

export interface ISkillCount {
    skill: ISkill;
    count: number;
    countInVacancies: number;
    countInCandidates: number;
    shareInVacancies: number;
    shareInCandidates: number;
    share: number;
}

export enum EProcessStatus {
    STATUS_OPEN = 'open',
    STATUS_DECLINE = 'decline',
    STATUS_CONTRACT = 'contract',
}

export enum ERecruitingStatus {
    STATUS_SUGGEST = 'suggest',
    STATUS_INTERVIEW = 'interview',
    STATUS_CONTRACT = 'contract',
    STATUS_FINISH = 'finish',
    STATUS_FAILED_TRIAL_PERIOD = 'failed_trial_period',
    STATUS_ABORT = 'abort',
}

export enum ERecruitingType {
    TYPE_DEFAULT = 'default',
    TYPE_PROJECT = 'project',
    TYPE_HEADHUNTING = 'headhunting'
}

export enum ERecruitingAbortedBy {
    CANDIDATE = 'candidate',
    COMPANY = 'company'
}

export enum ESuggestionStatus {
    OPEN = 'open',
    DECLINED = 'declined',
    ACCEPTED = 'accepted',
    FOLLOWUP = 'followup'
}

export enum ESuggestionProgressStatus {
    GRAY= -1,
    RED,
    YELLOW,
    GREEN
}

export interface ISuggestionGroup {
    id: number;
    title: string;
    default: boolean;
    ranking: number;
}

export interface ISuggestion {
    id: number;
    group: ISuggestionGroup;
    vacancy: IVacancyPartial;
    candidate: ICandidateListModel;
    source: TExternalCandidateSource;
    recruiting?: IRecruitingPartial;
    status: ESuggestionStatus;
    progressStatus: ESuggestionProgressStatus;
    comment?: string;
    suggestedByEmployee: IEmployeePartial;
    responsibleEmployee: IEmployeePartial;
    ranking: number;
    rating: number;
    currentProgressInformation: ISuggestionProgressInformation;
    callAppointmentAt?: string;
    lastActivity: string;
    declineReason?: string;
    declineReasonComment?: string;
    declineDate?: string;
    notReachableUntil?: string;
    created: string;
}

export interface ISuggestionProgressInformation {
    contactCounts: {
        [ECandidateSource.linkedIn]: number;
        [ECandidateSource.xing]: number;
        [ECandidateSource.freeLancerMap]: number;
        [ECandidateSource.jobcenter]: number;
        [ECandidateSource.further]: number;
        [ESuggestionAction.ACTION_MESSAGE_PORTAL]: number;
        [ESuggestionAction.ACTION_MESSAGE_MAIL]: number;
        [ESuggestionAction.ACTION_MESSAGE_MOBILE]: number;
        [ESuggestionAction.ACTION_CALL_NOT_REACHED]: number;
    };
    isInCallCoordination: boolean;
}

export interface ISuggestionPartial {
    id: number;
    candidate: ICandidatePartial,
    rating: number;
    source: ECandidateSource;
    vacancy: IVacancyPartial;
    created: string;
    declineReasonComment: string;
}

export interface ISuggestionForApi
    extends Partial<Omit<ISuggestion, 'employee' | 'vacancy' | 'candidate' | 'recruiting' | 'suggestedByEmployee' | 'responsibleEmployee'|'group'>> {
    vacancyId: number;
    groupId: number;
    candidateId: number;
    source: TExternalCandidateSource;
    recruitingId?: number;
    suggestedByEmployeeId: number;
    responsibleEmployeeId: number;
    contactAction?: ESuggestionAction;
    contactUserInput?: string;
    responsibleChanged?: boolean;
    responsibleChangedComment?: string;
    countryId?: number;
    name?: string;
    firstName?: string;
    salutation?: TSalutation;
    externalCandidateUrl?: string;
    externalCandidateReference?: string;
    currentJobDescription?: string;
    imageUrl?: string;
    externalSkills?: IPluginSkill[];
    externalLanguages?: IPluginLanguage[];
}

export interface IAlertPossibleResponsibleChange {
    alert: boolean;
    candidate?: ICandidate;
    currentResponsible?: IEmployee;
    possibleNewResponsible?: IEmployee;
}

export interface IProcessAggregation {
    status: 'open'|'decline'|'contract';
    source: ECandidateSource;
    title: string;
    titleType: 'vacancy'|'url'|'initiative';
    company: ICompanyPartial;
    vacancy?: IVacancyPartial;
    webVacancy?: IWebVacancyPartial;
    contract?: IContract;
    recruiting?: IRecruitingPartial;
    suggestion?: ISuggestionPartial;
    application?: IApplicationPartial;
    employees: IEmployeePartial[];
}

export interface IContract {
    id: number;
    vacancy?: IVacancyPartial;
    company: ICompanyPartial;
    responsibleEmployee: IEmployeePartial;
    responsibleContact?: ICompanyContactPartial;
    companyLocation?: ICompanyLocationPartial;
    candidate: ICandidatePartial;
    created: string;
    contractSalary?: number;
    contractSigningDate?: string;
    contractBeginningDate?: string;
    endOfTrialPeriod?: string;
    finishedTrialPeriod?: boolean;
    projectPlanedEnd?: string;
    projectEnd?: string;
    projectCommissionPercent?: number;
    projectValuedHoursPerMonth?: number;
    projectInvoiceCreationDay?: number;
    projectInvoiceCreationInterval?: number;
    billedCommission: number;
    openCommission: number;
}

export interface IRecruiting extends IBillingSetting {
    id: number;
    suggestedFor: 'vacancy'|'webVacancy'|'initiative';
    type: ERecruitingType;
    status: ERecruitingStatus;
    vacancy?: IVacancyPartial;
    webVacancy?: IWebVacancyPartial;
    url?: string;
    partner?: ICompanyPartial;
    source: ECandidateSource;
    company: ICompanyPartial;
    application?: IApplication;
    responsibleEmployee: IEmployeePartial;
    suggestion?: ISuggestionPartial;
    suggestedBy?: IEmployeePartial;
    responsibleContact?: ICompanyContactPartial;
    companyLocation?: ICompanyLocationPartial;
    candidate: ICandidatePartial;
    created: string;
    dateOfAbort?: string;
    abortedBy?: ERecruitingAbortedBy;
    abortComment?: string;
    comment?: string;
    desiredSalary?: number;
    recommendationBy?: string;
    matchScore: number;
    lastActivity: string;
    possibleCommission: number;
    billedCommission: number;
    openCommission: number;
    congratulationMailSend: EMailSendStatus;
    declineReason?: ERecruitingDeclineReason;
    employeeTimes: IGroupedTimePerEmployeeData[];
    candidateProfiles: ICandidateProfiles;
    expectedSalaryString: string;
    sharedDocumentIds: number[];
    contract?: IContract;
}

export enum EMailSendStatus {
    open,
    prepared,
    send
}

export interface IRecruitingTimelineInformation {
    id: number,
    offerOrInterview: 'offer'|'interview',
    cancelled: boolean,
    contractOffered: boolean;
    contractOfferSalary?: number;
    feedbackExists: boolean,
    round: number,
    type: EInterviewType,
    dateTime: string
}

export interface IRecruitingListModel {
    id: number;
    source: ECandidateSource;
    type: ERecruitingType;
    comment: string;
    status: ERecruitingStatus;
    vacancy?: IVacancyPartial;
    webVacancy?: IWebVacancyPartial;
    url?: string;
    partner?: ICompanyPartial;
    company: ICompanyPartial;
    responsibleEmployee: IEmployeePartial;
    responsibleContact?: ICompanyContactPartial;
    suggestedBy?: IEmployeePartial;
    candidate: ICandidatePartial;
    recruitingSuggestion?: ISuggestionPartial;
    timeline: IRecruitingTimelineInformation[];
    interviewActionIsRequired: boolean;
    customerActionRequired: boolean;
    actionRequired: boolean;
    nextAppointment?: string;
    nextInterviewType?: EInterviewType;
    nextInterviewContact?: ICompanyContactPartial;
    dateOfAbort?: string;
    abortedBy?: ERecruitingAbortedBy;
    abortComment: string;
    contractSalary?: number;
    contractSigningDate?: string;
    contractBeginningDate?: string;
    endOfTrialPeriod?: string;
    finishedTrialPeriod?: boolean;
    possibleCommission: number;
    billedCommission: number;
    openCommission: number;
    recommendationBy?: string;
    matchScore: number;
    lastActivity: string;
    declineReason?: ERecruitingDeclineReason;
    employeeTimes: IGroupedTimePerEmployeeData[];
    candidateProfiles: ICandidateProfiles;
    created: string;
    expectedSalaryString?: string;
    desiredYearSalary?: number;
    inquiries: ICustomerInquiry[];
    hot: boolean;
}

export interface IRecruitingPartial {
    id: number;
    type: ERecruitingType;
    status: ERecruitingStatus;
    responsibleEmployee: IEmployeePartial;
    candidate: ICandidatePartial;
    vacancy: IVacancyPartial;
    company: ICompanyPartial;
    finishedTrialPeriod?: boolean;
    dateOfAbort?: string;
    abortedBy?: ERecruitingAbortedBy;
    declineReason?: ERecruitingDeclineReason;
    abortComment?: string;
    created: string;
    contractSalary?: number;
    contractSigningDate?: string;
    contractBeginningDate?: string;
    endOfTrialPeriod?: string;
    possibleCommission?: number;
}

export interface IWebVacancy {
    id: number;
    online: boolean;
    url: string;
    locations: string[];
    locationModels: ICompanyLocationPartial[];
    dateOfPublication: string;
    title: string;
    description: string;
    vacancies: IVacancyPartial[];
    company: ICompany;
    taskTextsFromAdvertisement?: string[];
    skillTextsFromAdvertisement?: string[];
    created: string;
    onlineSince: string;
    skills: IVacancySkill[];
    languages: ILanguage[];
    generateMatchingCache: boolean;
    filter?: string;
    partTime: boolean;
    hide: boolean;
}

export interface IWebVacancyListModel {
    id: number;
    online: boolean;
    url: string;
    locations: string[];
    matchedLocations: ICompanyLocationPartial[];
    dateOfPublication: string;
    title: string;
    description: string;
    vacancies: IVacancyPartial[];
    company: ICompanyPartial;
    taskTextsFromAdvertisement?: string[];
    skillTextsFromAdvertisement?: string[];
    onlineSince: string;
    hide: boolean;
}

export interface IWebVacancyPartial {
    id: number;
    online: boolean;
    url: string;
    title: string;
    company: ICompany;
    locations: string[];
}

export interface IRecruitingForAPI extends Partial<Omit<
    IRecruiting,
    | 'candidate'
    | 'partner'
    | 'responsibleEmployee'
    | 'responsibleContact'
    | 'suggestedBy'
    | 'vacancy'
    | 'company'
    | 'webVacancy'
    >> {
    hot?: boolean;
    responsibleEmployeeId: number;
    suggestedById?: number;
    vacancyId?: number;
    webVacancyId?: number;
    partnerId?: number;
    responsibleContactId?: number;
    companyLocationId?: number;
    validateFreelancer: boolean;
    candidateFollowupDate?: string;
    contractSalary?: number;
    contractSigningDate?: string;
    contractBeginningDate?: string;
    endOfTrialPeriod?: string;
    finishedTrialPeriod?: boolean;
    projectPlanedEnd?: string;
    projectEnd?: string;
    projectCommissionPercent?: number;
    projectValuedHoursPerMonth?: number;
    projectInvoiceCreationDay?: number;
    projectInvoiceCreationInterval?: number;
    billedCommission: number;
    openCommission: number;
}

export type TBrainzEntity =
    | IActivity
    | ISuggestionGroup
    | IProcessAggregation
    | ICandidateMailAddress
    | ICandidateAddress
    | ICandidateUrl
    | ICandidatePhoneNumber
    | ICustomerRecruitingListModel
    | IChatNode
    | IEntitySkill
    | IJobTitle
    | ISuccessActivity
    | ICompanyDocument
    | ISuggestionPartial
    | IRecruitingPartial
    | IWebVacancyPartial
    | IMailPartial
    | IRecruitingDocument
    | IMailboxMail
    | IRecruitingListModel
    | IApplication
    | ICompany
    | IVacancyOrWebVacancy
    | ICandidateListModel
    | ICandidate
    | ICompanyContact
    | IWebVacancyListModel
    | ICompanyLocation
    | ICompanyPartial
    | IEmployee
    | IBranch
    | ISkill
    | ISkillDetail
    | IVacancy
    | IRecruiting
    | ICandidateDocument
    | ISuggestion
    | ISuggestion
    | ICandidatePartial
    | IVacancyListModel
    | IVacancyPartial
    | ITodo
    | INews
    | IWebVacancy
    | IInvoice
    | IBillableData
    | IMailboxFolderTreeNode;

export interface IEntitiesById<E extends TBrainzEntity> {
    [entityId: number]: E;
}

export interface IEntitiesByEntityId {
    [entityId: number]: number[];
}

export interface IEntitiesByCompanyId {
    [companyId: number]: number[];
}

export interface IEntitiesByCandidateId {
    [candidateId: number]: number[];
}

export interface IEntitiesByVacancyId {
    [vacancyId: number]: number[];
}

export interface IEntitiesBySuggestionId {
    [suggestionId: number]: number[];
}

export interface IEntitiesByApplicationId {
    [applicationId: number]: number[];
}

export interface IEntitiesByTodoId {
    [todoId: number]: number[];
}

export interface IEntitiesByEmployeeId {
    [employeeId: number]: number[];
}

export interface IEntitiesByRecruitingId {
    [recruitingId: number]: number[];
}

export interface IEmailAddress {
    email: string;
    name: string;
    firstname: string;
}

export interface IEntitiesState {
    news: {
        byId: IEntitiesById<INews>;
        order: number[];
    },
    mail: {
        byId: IEntitiesById<IMailboxMail>;
        mailbox: {
            [mailboxId: number]: IMailbox;
        };
        order: number[];
        total: number;
    },
    mailAddresses: IEmailAddress[];
    todo: {
        currentRecord?: ITodo;
        byId: IEntitiesById<ITodo>;
        withoutDueByEmployee: IEntitiesByEmployeeId;
        orderOfLoggedInEmployee: number[];
    },
    applications: {
        total: number;
        byId: IEntitiesById<IApplication>;
        order: number[]
        byVacancy: IEntitiesByVacancyId;
        totalByVacancy: {[id: number]: number}
        byCandidate: IEntitiesByCandidateId;
        totalByCandidate: {[id: number]: number}
        byCompany: IEntitiesByCompanyId;
        totalByCompany: {[id: number]: number}
    },
    activityChat: {
        global?: IChat
        byRecruiting: { [recruitingId: number]: IChat };
        byVacancy: { [vacancyId: number]: IChat };
        byCompany: { [companyId: number]: IChat };
        byCompanyContact: { [companyContactId: number]: IChat };
        byCandidate: { [candidateId: number]: IChat };
        bySuggestion: { [recruitingSuggestionId: number]: IChat };
    },
    activities: {
        total: number;
        byId: IEntitiesById<IActivity>;
        order: number[];
        byRecruiting: IEntitiesByRecruitingId;
        totalByRecruiting: {[id: number]: number}
        byVacancy: IEntitiesByVacancyId;
        totalByVacancy: {[id: number]: number}
        byWebVacancy: IEntitiesByVacancyId;
        totalByWebVacancy: {[id: number]: number}
        bySuggestion: IEntitiesBySuggestionId;
        totalBySuggestion: {[id: number]: number}
        byApplications: IEntitiesByApplicationId;
        totalByApplication: {[id: number]: number}
        byTodo: IEntitiesByTodoId;
        totalByTodo: {[id: number]: number}
        byCompany: IEntitiesByCompanyId;
        totalByCompany: {[id: number]: number}
        byCandidate: IEntitiesByCandidateId;
        totalByCandidate: {[id: number]: number},
        orderListView: number[];
        totalTimeOrderListView: number;
        successActivities: ISuccessActivity[];
    };
    employeeRecruiting: {
        byId: IEntitiesById<IRecruiting>;
        byEmployee: IEntitiesByEmployeeId;
    };
    companies: {
        total: number;
        byId: IEntitiesById<ICompany>;
        order: number[];
        orderAll: number[];
    };
    vacancies: {
        pinChanged: boolean;
        totalOpen: number;
        totalArchived: number;
        listModelById: IEntitiesById<IVacancyListModel>;
        byId: IEntitiesById<IVacancy>;
        order: number[];
        orderArchived: number[];
        byCompany: IEntitiesByVacancyId;
        byCompanyContact: IEntitiesByVacancyId;
    };
    recruitingSuggestion: {
        byId: IEntitiesById<ISuggestion>;
        byVacancy: IEntitiesByVacancyId;
        totalByCandidate: {[id: number]: number}
        byCandidate: IEntitiesByCandidateId;
        totalByVacancy: {[id: number]: number}
        orderTop: number[];
        orderListView: number[];
    };
    vacancyAndWebVacancy: {
        byId: IEntitiesById<IVacancyOrWebVacancy>;
        orderCandidateSearch: number[];
        totalCandidateSearch: number;
    },
    candidates: {
        externalCandidateInformation?: IExternalCandidateInformation;
        total: number;
        byId: IEntitiesById<ICandidate>;
        listModelById: IEntitiesById<ICandidateListModel>;
        order: number[];
        orderBirthday: number[];
        orderWebVacancySearch: number[];
        totalWebVacancySearch: number;
        orderVacancySearch: number[];
        totalVacancySearch: number;
    };
    invoice: {
        total: number;
        sum: number;
        byId: IEntitiesById<IInvoice>;
        byRecruiting: IEntitiesByRecruitingId;
        byCompany: IEntitiesByCompanyId;
        order: number[];
    },
    processAggregation: {
        byCandidate: {
            [candidateId: number]: IProcessAggregation[]
        };
    },
    recruiting: {
        statusChangedToAbort: boolean;
        statusChangedToContract: boolean;
        byId: IEntitiesById<IRecruiting>;
        listModelById: IEntitiesById<IRecruitingListModel>;
        byVacancy: IEntitiesByVacancyId;
        byCandidate: IEntitiesByCandidateId;
        byCompany: IEntitiesByCompanyId;
        byCompanyContact: IEntitiesByCompanyId;
        totalByCompany: {[companyId: number]: number},
        totalByCompanyContact: {[companyId: number]: number},
        totalByCandidate: {[candidateId: number]: number},
        totalByVacancy: {[vacancyId: number]: number},
        orderSuggest: number[];
        orderLast7Days: number[];
        totalSuggest: number;
        totalApplications: number;
        orderInterview: number[];
        totalInterview: number;
        orderContract: number[];
        totalContract: number;
        openCommissionSumContract: number;
        orderAbort: number[];
        totalAbort: number;
        orderChartSelection: number[];
        orderListView: number[];
        totalListView: number;
    };
    candidateDocuments: {
        byId: IEntitiesById<ICandidateDocument>;
        byCandidate: IEntitiesByCandidateId;
    };
    companyDocuments: {
        byId: IEntitiesById<ICompanyDocument>;
        byCompany: IEntitiesByCompanyId;
    };
    recruitingDocuments: {
        byId: IEntitiesById<IRecruitingDocument>;
        byRecruiting: IEntitiesByRecruitingId;
    };
    companyContacts: {
        byId: IEntitiesById<ICompanyContact>;
        byCompany: IEntitiesByCompanyId;
        byCompanyForDropdown: {
            [companyId: number]: ICompanyContact[]
        };
        orderBirthday: number[];
        orderNoBirthdate: number[];
    };
    companyLocations: {
        byId: IEntitiesById<ICompanyLocation>;
        byCompany: IEntitiesByCompanyId;
        byCompanyForDropdown: IEntitiesByCompanyId;
        order: number[];
    };
    employees: {
        currentRecord?: IEmployee;
        byId: IEntitiesById<IEmployee>;
        order: number[],
        total: number
    };
    jobTemplate: {
        currentRecord?: IJobTemplate;
        byId: IEntitiesById<IJobTemplate>;
        order: number[],
        total: number,
        names: IJobTemplateName[]
    };
    jobTitle: {
        byId: IEntitiesById<IJobTitle>;
        order: number[],
        total: number,
    };
    webVacancy: {
        currentRecord?: IWebVacancy;
        listModelById:  IEntitiesById<IWebVacancyListModel>;
        byId: IEntitiesById<IWebVacancy>;
        byCompany: IEntitiesByCompanyId;
        order: number[];
        total: number;
    },
    branches: {
        byId: IEntitiesById<IBranch>;
        array: IBranch[];
    };
    skills: {
        currentRecord?: {
            skill: ISkill,
            hasChildren: boolean
        };
        currentExternalRecord?: IExternalSkill;
        array: ISkill[];
        tree: ISkillTreeNode[];
        treeTotal: number;
    };
    suggestSkills: ISkillCount[],
}

export interface IBranch {
    id: number;
    name: string;
}
export const preloadedStateEntities: IEntitiesState = {
    suggestSkills: [],
    applications: {
        total: 0,
        byId: {},
        order: [],
        byVacancy: {},
        totalByVacancy: [],
        byCompany: {},
        totalByCompany: [],
        byCandidate: {},
        totalByCandidate: []
    },
    invoice: {
        byId: {},
        order: [],
        total: 0,
        sum: 0,
        byRecruiting: {},
        byCompany: {}
    },
    news: {
        byId: {},
        order: []
    },
    mail: {
        byId: {},
        mailbox: {},
        order: [],
        total: 0
    },
    todo: {
        byId: {},
        withoutDueByEmployee: {},
        orderOfLoggedInEmployee: [],
    },
    vacancyAndWebVacancy: {
        byId: {},
        orderCandidateSearch: [],
        totalCandidateSearch: 0
    },
    mailAddresses: [],
    activityChat: {
        byRecruiting: {},
        byCompany: {},
        byVacancy: {},
        bySuggestion: {},
        byCandidate: {},
        byCompanyContact: {}
    },
    activities: {
        total: 0,
        byId: {},
        order: [],
        byCandidate: {},
        byCompany: {},
        byVacancy: {},
        byWebVacancy: {},
        byRecruiting: {},
        bySuggestion: {},
        byApplications: {},
        byTodo: {},
        totalByTodo: {},
        totalByVacancy: {},
        totalByCompany: {},
        totalByCandidate: {},
        totalBySuggestion: {},
        totalByWebVacancy: {},
        totalByRecruiting: {},
        totalByApplication: {},
        orderListView: [],
        successActivities: [],
        totalTimeOrderListView: 0
    },
    employeeRecruiting: {
        byId: {},
        byEmployee: [],
    },
    companies: {
        total: 0,
        byId: {},
        order: [],
        orderAll: []
    },
    vacancies: {
        pinChanged: false,
        totalOpen: 0,
        totalArchived: 0,
        listModelById: {},
        byId: {},
        order: [],
        orderArchived: [],
        byCompany: {},
        byCompanyContact: {}
    },
    webVacancy: {
        listModelById: {},
        byId: {},
        order: [],
        total: 0,
        byCompany: {}
    },
    candidates: {
        total: 0,
        byId: {},
        listModelById: {},
        order: [],
        orderBirthday: [],
        orderWebVacancySearch: [],
        totalWebVacancySearch: 0,
        orderVacancySearch: [],
        totalVacancySearch: 0
    },
    jobTemplate: {
        byId: {},
        names: [],
        total: 0,
        order: []
    },
    jobTitle: {
        byId: {},
        order: [],
        total: 0
    },
    recruitingSuggestion: {
        byId:{},
        byVacancy: {},
        totalByVacancy: {},
        byCandidate: {},
        totalByCandidate: {},
        orderTop: [],
        orderListView: [],
    },
    processAggregation: {
        byCandidate: {}
    },
    recruiting: {
        statusChangedToAbort: false,
        statusChangedToContract: false,
        listModelById: {},
        byId: {},
        byVacancy: {},
        byCandidate: {},
        byCompany: {},
        totalByCandidate: {},
        totalByCompany: {},
        totalByVacancy: {},
        orderSuggest: [],
        orderLast7Days: [],
        orderInterview: [],
        orderContract: [],
        orderAbort: [],
        orderChartSelection: [],
        totalAbort: 0,
        totalContract: 0,
        openCommissionSumContract: 0,
        totalInterview: 0,
        totalSuggest: 0,
        totalApplications: 0,
        orderListView: [],
        totalListView: 0,
        byCompanyContact: {},
        totalByCompanyContact: {}
    },
    candidateDocuments: {
        byId: {},
        byCandidate: {},
    },
    companyDocuments: {
        byId: {},
        byCompany: {},
    },
    recruitingDocuments: {
        byId: {},
        byRecruiting: {},
    },
    companyContacts: {
        byId: {},
        byCompany: {},
        byCompanyForDropdown: {},
        orderBirthday: [],
        orderNoBirthdate: []
    },
    companyLocations: {
        byId: {},
        byCompany: {},
        byCompanyForDropdown: {},
        order: []
    },
    employees: {
        byId: {},
        order: [],
        total: 0
    },
    branches: {
        byId: {},
        array: [],
    },
    skills: {
        array: [],
        tree: [],
        treeTotal: 0
    },
};

export enum EEntities {
    Company,
    Application,
    VacancyApplication,
    CompanyContact,
    CompanyLocation,
    CompanyDocument,
    Activity,
    ActivityList,
    Todo,
    CompanyActivity,
    CompanyContactActivity,
    CandidateActivity,
    VacancyActivity,
    SuccessActivity,
    WebVacancyActivity,
    SuggestionActivity,
    TodoActivity,
    RecruitingActivity,
    Employee,
    JobTemplate,
    JobTemplateJobTitles,
    JobTemplateSkills,
    WebVacancy,
    Candidate,
    WebVacancyCandidateSearch,
    VacancyCandidateSearch,
    CandidateVacancySearch,
    Invoice,
    RecruitingInvoice,
    CompanyInvoice,
    InvoiceItems,
    BillableRecruiting,
    SkillSelectSearch,
    SkillSelectSelected,
    JobTitleOverview,
    JobTitleSelectSearch,
    JobTitleSelectSelected,
    CandidateOpenApplication,
    CandidateBirthday,
    CompanyContactBirthday,
    Suggestion,
    VacancySuggestion,
    TopSuggestions,
    Recruiting,
    Process,
    VacancyRecruiting,
    CandidateRecruiting,
    CandidateProfession,
    CompanyRecruiting,
    RecruitingsSuggest,
    RecruitingsInterview,
    RecruitingsContract,
    RecruitingsAbort,
    RecruitingsTrialPeriodFailed,
    RecruitingsFinish,
    EmployeeRecruiting,
    RecruitingsOfLast7Days,
    RecruitingOfCandidate,
    RecruitingOfCompany,
    RecruitingOfCompanyContact,
    Branch,
    Vacancy,
    VacancyList,
    VacancyArchive,
    CandidateDocument,
    RecruitingDocument,
    CompanyVacancy,
    CompanyContactVacancy,
    Skill,
    EmployeeAppointment,
}

export interface ICompanyContactForApi extends Partial<Omit<ICompanyContact, 'company'>> {
    companyId?: number;
}

export enum EActivityType {
    phone = 'phone',
    phoneIncoming = 'phone_incoming',
    phoneNotReached = 'phone_not_reached',
    info = 'info',
    personal = 'personal',
    mail = 'mail',
    mailReceived = 'mail_received',
    mobileMessage = 'mobile_message',
    mobileMessageReceived = 'mobile_message_received',
    systemMail = 'system_mail',
    systemInfo = 'system_info',
    interview = 'interview',
    contractOffer = 'contract_offer',
    candidateInterview = 'candidate_interview',
    feedback = 'feedback',
    suggestionAction = 'suggestion_action'
}

export type TActivityType =
    | EActivityType.phone
    | EActivityType.phoneIncoming
    | EActivityType.phoneNotReached
    | EActivityType.info
    | EActivityType.personal
    | EActivityType.mail
    | EActivityType.mailReceived
    | EActivityType.systemMail
    | EActivityType.systemInfo
    | EActivityType.interview
    | EActivityType.feedback
    | EActivityType.candidateInterview
    | EActivityType.mobileMessage
    | EActivityType.mobileMessageReceived;

export enum EInterviewType {
    phone = 'phone',
    personal = 'personal',
    online = 'online',
    trial = 'trial',
    coding = 'coding',
    contract = 'contract',
}

export enum EFeedbackType {
    start = 'start',
    trial_end = 'trial_end',
    default = 'default',
}

export enum ECandidateSource {
    brainzShare = 'brainz_share',
    brainz = 'brainz',
    xing = 'xing',
    jobcenter = 'jobcenter',
    linkedIn = 'linkedIn',
    freeLancerMap = 'Freelancermap',
    homepage = 'homepage',
    github = 'github',
    further = 'further',
    indeed = 'indeed',
    joyn = 'joyn',
    recommendation = 'recommendation'
}

export type TExternalCandidateSource =
    | ECandidateSource.brainzShare
    | ECandidateSource.brainz
    | ECandidateSource.xing
    | ECandidateSource.jobcenter
    | ECandidateSource.linkedIn
    | ECandidateSource.freeLancerMap
    | ECandidateSource.homepage
    | ECandidateSource.further
    | ECandidateSource.joyn
    | ECandidateSource.indeed
    | ECandidateSource.github
    | ECandidateSource.recommendation;

export enum EActivityModule {
    mail = 'mail',
    candidate = 'candidate',
    application = 'application',
    company = 'company',
    companyContact = 'company_contact',
    vacancy = 'vacancy',
    webVacancy = 'web_vacancy',
    recruiting = 'recruiting',
    recruitingSuggestion = 'recruiting_suggestion',
    todo = 'todo'
}

export enum ESuggestionAction {
    ACTION_BOOKED_JOBCENTER = 'ACTION_BOOKED_JOBCENTER',
    ACTION_MESSAGE_PORTAL = 'ACTION_MESSAGE_PORTAL',
    ACTION_MESSAGE_MAIL = 'ACTION_MESSAGE_MAIL',
    ACTION_MESSAGE_MOBILE = 'ACTION_MESSAGE_MOBILE',
    ACTION_MESSAGE_RECEIVED_PORTAL = 'ACTION_MESSAGE_RECEIVED_PORTAL',
    ACTION_MESSAGE_RECEIVED_MAIL = 'ACTION_MESSAGE_RECEIVED_MAIL',
    ACTION_MESSAGE_RECEIVED_MOBILE = 'ACTION_MESSAGE_RECEIVED_MOBILE',
    ACTION_CALL_COORDINATION = 'ACTION_CALL_COORDINATION',
    ACTION_CALL_DATE_SET = 'ACTION_CALL_DATE_SET',
    ACTION_CALL_DATE_CHANGE = 'ACTION_CALL_DATE_CHANGE',
    ACTION_CALL = 'ACTION_CALL',
    ACTION_CALL_RECEIVED = 'ACTION_CALL_RECEIVED',
    ACTION_CALL_NOT_REACHED = 'ACTION_CALL_NOT_REACHED',
    ACTION_LETTER_SEND = 'ACTION_LETTER_SEND',
    ACTION_NOT_REACHABLE_UNTIL = 'ACTION_NOT_REACHABLE_UNTIL',
    ACTION_ANSWER_BY_CANDIDATE = 'ACTION_ANSWER_BY_CANDIDATE'
}

export enum EActivityAction {
    addTodo = 'add_todo',
    addCompany = 'add_company',
    addCandidate = 'add_candidate',
    addVacancy = 'add_vacancy',
    addRecruiting = 'add_recruiting',
    editRecruiting = 'edit_recruiting',
    editVacancy = 'edit_vacancy',
    addWebVacancy = 'add_web_vacancy',
    editWebVacancy = 'edit_web_vacancy',
    addActivity = 'add_activity',
    addSuggestion = 'add_recruiting_suggestion',
    editSuggestion = 'edit_recruiting_suggestion',
    acceptSuggestion = 'accept_recruiting_suggestion',
    declineSuggestion = 'decline_recruiting_suggestion',
    sendBirthdayMail = 'send_birthday_mail',
    sendApplicationReceivedMail = 'send_application_received_mail',
    sendApplicationDeclinedMail = 'send_application_declined_mail',
    sendContractCongratulationMail = 'send_contract_congratulation_mail',
    acceptApplication = 'accept_application',
    declineApplication = 'decline_application',
    addApplication = 'add_application',
    addSuggestionAction = 'add_suggestion_action',
    mailboxMail = 'mailbox_mail'
}

export type TActivityAction =
    | EActivityAction.addTodo
    | EActivityAction.addCompany
    | EActivityAction.addCandidate
    | EActivityAction.addVacancy
    | EActivityAction.addWebVacancy
    | EActivityAction.editWebVacancy
    | EActivityAction.addRecruiting
    | EActivityAction.editRecruiting
    | EActivityAction.editVacancy
    | EActivityAction.addActivity
    | EActivityAction.addSuggestion
    | EActivityAction.editSuggestion
    | EActivityAction.acceptSuggestion
    | EActivityAction.acceptApplication
    | EActivityAction.declineApplication
    | EActivityAction.declineSuggestion
    | EActivityAction.sendApplicationReceivedMail
    | EActivityAction.sendBirthdayMail
    | EActivityAction.sendContractCongratulationMail
    | EActivityAction.addSuggestionAction
    | EActivityAction.sendApplicationDeclinedMail
    | EActivityAction.mailboxMail;

export type TSalutation = undefined | 'mr' | 'mrs' | 'divers';

export type TWorkExperience = undefined | 'less_than_2' | 'up_to_5' | 'more_than_5' | 'more_than_10';

export enum EHomeOfficeDesire{
    no,
    nice_to_have,
    partially,
    complete
}

export enum ESuggestionDeclineReason {
    REASON_MISSING_FEEDBACK = 'REASON_MISSING_FEEDBACK',
    REASON_NO_INTEREST = 'REASON_NO_INTEREST',
    REASON_NOT_MATCHING = 'REASON_NOT_MATCHING',
    REASON_OTHER = 'REASON_OTHER',
    REASON_CONDITIONS = 'REASON_CONDITIONS'
}

export enum ERecruitingDeclineReason {
    REASON_MISSING_KNOWLEDGE = 'REASON_MISSING_KNOWLEDGE',
    REASON_CANDIDATE_TYPUS_NOT_MATCHING = 'REASON_CANDIDATE_TYPUS_NOT_MATCHING',
    REASON_COMPANY_NOT_MATCHING = 'REASON_COMPANY_NOT_MATCHING',
    REASON_CONDITIONS = 'REASON_CONDITIONS',
    REASON_OTHER_CANDIDATE = 'REASON_OTHER_CANDIDATE',
    REASON_BETTER_OFFER = 'REASON_BETTER_OFFER',
    REASON_DURATION_TOO_LONG = 'REASON_DURATION_TOO_LONG',
    REASON_OTHER = 'REASON_OTHER',
}

export enum EHomeOfficePossibility{
    no,
    partially,
    complete
}

export type TPartTimeFullTime =
    | 'FULL_TIME'
    | 'PART_TIME_20_HOURS'
    | 'PART_TIME_25_HOURS'
    | 'PART_TIME_30_HOURS'
    | 'PART_TIME_35_HOURS'
    | 'FREELANCER';

export type TNoticePeriod = undefined | 'no' | '4_weeks' | '6_weeks' | '8_weeks' | '12_weeks' | 'more_than_12_weeks';

export type TCandidateDocumentType = ECandidateDocumentType.CV | ECandidateDocumentType.PHOTO | ECandidateDocumentType.EDUCATION | ECandidateDocumentType.WRITE_TO | ECandidateDocumentType.EMPLOYMENT_CERTIFICATE | ECandidateDocumentType.OTHERS;
export type TRecruitingDocumentType = ERecruitingDocumentType.CONTRACT | ERecruitingDocumentType.OTHERS;
export type TCompanyDocumentType = ECompanyDocumentType.AGREEMENT | ECompanyDocumentType.OTHERS;

export enum ECandidateDocumentType {
    CV = 'cv',
    WRITE_TO = 'write_to',
    INTERN_PROFILE = 'intern_profile',
    EMPLOYMENT_CERTIFICATE = 'employment_certificate',
    EDUCATION = 'education',
    PHOTO = 'photo',
    OTHERS = 'others',
}
export enum ERecruitingDocumentType {
    CONTRACT = 'contract',
    OTHERS = 'others',
}
export enum ECompanyDocumentType {
    AGREEMENT = 'agreement',
    OTHERS = 'others',
}

export interface IJobTemplateSkill extends IEntitySkill {
    identifier: 'jobTemplate';
}

export interface IJobTemplate {
    id: number;
    name: string;
    skills: IJobTemplateSkill[];
    jobTitles: IJobTitle[];
}

export interface IJobTemplateName {
    id: number;
    name: string;
}

export interface IJobTemplateForApi {
    id?: number;
    name: string;
    skills: {skillId: number}[],
    jobTitleIds: number[]
}

export interface IJobTitle {
    id: number;
    title: string;
    skills: IJobTemplateSkill[];
}

export interface IJobTitleForApi {
    id: number;
    title?: string;
    ignore?: boolean;
    jobTemplateId?: number;
}

export interface IEmployee extends IPerson{
    id: number;
    fullName: string;
    name: string;
    firstName: string;
    address: string;
    zipCode: string;
    city: string;
    salutation: TSalutation;
    title: string;
    phoneNumber: string;
    mobileNumber: string;
    comment: string;
    user: IUser;
    onlyShowMine: boolean;
}

export interface IEmployeeForApi {
    id?: number;
    name: string;
    firstName: string;
    address: string;
    zipCode: string;
    city: string;
    salutation: string;
    title: string;
    phoneNumber: string;
    mobileNumber: string;
    comment: string;
    email: string;
    newPassword: string;
    renewPassword: boolean;
}

export interface IEmployeePartial extends IPerson {
    id: number;
    name: string;
    firstName: string;
    fullName: string;
}

export interface IUserPartial extends IPerson {
    id: number;
    isContact: boolean;
    isEmployee: boolean;
    person: IPerson;
}

export interface IUser {
    id: number;
    renewPassword: boolean;
    hideUser: boolean;
    login: string;
    roles: TRole[];
}

export interface IPdfDocument {
    fileContent: string;
    fileName: string;
    fileType: 'application/pdf';
}

export interface IApplication {
    id: number;
    candidate: ICandidate;
    vacancy?: IVacancyPartial;
    company?: ICompanyPartial;
    recruiting?: IRecruitingPartial;
    status: EApplicationStatus;
    declinedAt: string;
    responsibleEmployee?: IEmployeePartial;
    created: string;
    candidateProfiles: ICandidateProfiles;
    url?: string;
}

export interface IApplicationPartial {
    id: number;
    candidate: ICandidatePartial;
    vacancy?: IVacancyPartial;
    url?: string;
    created: string;
}

export interface ICandidatePartial extends IPerson {
    id: number;
    type: 'candidate'|'acquisition';
    topCandidate: boolean;
    followupDate?: string;
    name: string;
    firstName: string;
    fullName: string;
    age?: number;
    currentJobDescription: string;
    desiredJobDescription: string;
    responsible?: IEmployeePartial;
    lastActivity: string;
    latestDocumentCvId?: number;
    source: ECandidateSource;
    created: string;
}

export interface ICompanyPartial {
    id: number;
    isCustomer: boolean;
    rating: number;
    name: string;
    short: string;
    parentCompany?: ICompanyPartial;
    responsibleEmployee: IEmployeePartial
    totalContracts: number;
    crawler: boolean;
}

export interface IRecruitingAggregation {
    total: number;
    running: number;
    contract: number;
    declined: number;
    totalEstimatedTime: number;
    averageEstimatedTime: number;
}

export interface ISuggestionAggregation {
    total: number;
    running: number;
    suggested: number;
    declined: number;
    totalEstimatedTime: number;
    averageEstimatedTime: number;
}

export interface IVacancyAnalytics {
    vacancy: IVacancyPartial;
    recruitingAggregation: IRecruitingAggregation;
    suggestionAggregation: ISuggestionAggregation;
}

export interface ICompanyAnalytics {
    company: ICompanyPartial;
    recruitingAggregation: IRecruitingAggregation;
    suggestionAggregation: ISuggestionAggregation;
    vacancyAnalytics: IVacancyAnalytics[];
}
